import '../styles/global.scss';
import '../styles/pages/privacy.scss';

import {HeadFC, Link} from "gatsby";

import {
    BigLogoImage
} from "../images";
import React, {useState} from 'react'
import MainFooter from '../components/MainFooter';

const Privacy = () => {

    return (
        <>
            <div className="DocPage">
                <div className="DocPageLogo">
                    <Link to="/">
                        <img src={BigLogoImage} width={200} alt=""/>
                    </Link>
                </div>
                <div className="DocPageWrapper">
                    <h2>PRIVACY POLICY</h2>
                    <p>Edition effective since: June 23, 2022</p>
                    <p>We respect your privacy and are committed to protecting your personal data. This privacy policy will inform you as to how we look after your personal data when you visit our website (regardless of where you visit it from) or use mobile app and tell you about your privacy rights and how the law protects you.</p>
                    <p>This privacy policy is provided in a layered format so you can click through to the specific areas set out below. Please also use the Glossary to understand the meaning of some of the terms used in this privacy policy.</p>
                    <ul>
                        <li>1. Introduction</li>
                        <li>2. Which data we collect about you?</li>
                        <li>3. How we collect your personal data?</li>
                        <li>4. How we use your personal data?</li>
                        <li>5. Disclosures of your personal data</li>
                        <li>6. International transfers</li>
                        <li>7. Data security</li>
                        <li>8. How long we will use your data?</li>
                        <li>9. Your legal rights</li>
                        <li>10. Information about our use of cookies</li>
                    </ul>

                    <h2>1. INTRODUCTION</h2>
                    <b>1.1. Definitions</b> <br />
                    <p>
                        “We” (as defined below) adopted this Privacy Policy and act as the data controller of our Website, offering you the “Software” (as defined below). A “controller” is a
                        person or organization who alone or jointly determines the purposes for which, and the manner in which, any personal data is, or is likely to be, processed. <br /><br />
                        We are: <br />
                        AEER Platform Inc, a company organized under the laws of the State of Delaware, United States, with the following registered address: 8 The Green Ste A Dover,
                        DE 19901, United States of America (also: “AEER”, “AEER Platform”, “we”, “We”, “our”, “us”, as applicable). <br /><br />
                        “You” - references in this Policy to “you” or “your” are references to individuals who use the Website, mobile app, Software and/or Service. The Website, Software and
                        Service are not intended for children, and we do not knowingly collect personal data relating to children. <br /><br />
                        “Software” – is a software provided by AEER Platform or AEER mobile app. Some features may be specified in Subscription Plan. <br /><br />
                        “Service” – is a service for providing “Software”, may be based on its Subscription Plan and other legally binding terms hereunder; Service may include any other
                        services, not necessarily with use of Software. Service may be delivered via Website of mobile app. <br /><br />
                        “Subscription platform” (or “Platform”) - is a software solution that allows AEER to sellsubscriptions, products orservices online, integrating with payment gateways
                        to bill Customers on a recurring basis. <br /><br />
                        Privacy Policy (also “Policy”): <br />
                        - applies to any information or data collected by us as a controller for purposes, such as information collected on our websites, mobile app or through other
                        channels for marketing purposes; <br />
                        - applicable together with Cookie Policy.
                    </p>
                    <p>
                        Website - website available at URL: https://aeer-platform.com/. <br /><br />
                        Mobile app – AEER mobile application that can be downloaded via Appstore or Google Play. <br /><br />
                        Legitimate Interest means the interest of our business in conducting and managing our business to enable us to give you the best service/product and the best and
                        most secure experience. We consider and balance any potential impact on you (both positive and negative) and your rights before we process your personal data for
                        our legitimate interests. We do not use your personal data for activities where our interests are overridden by the impact on you (unless we have your consent or are
                        otherwise required or permitted to by law). You can obtain further information about how we assess our legitimate interests against any potential impact on you in
                        respect of specific activities by contacting us. <br /><br />
                        Performance of Contract means processing your data where it is necessary for the performance of a contract to which you are a party or to take steps at your request
                        before entering into such a contract. For example, SaaS agreement is a contract. <br /><br />
                        Comply with a legal or regulatory obligation means processing your personal data where it is necessary for compliance with a legal or regulatory obligation that we
                        are subject to. <br /><br />

                        <b>External Third Parties:</b> <br />
                        • Service providers who provide IT and system administration services.<br />
                        • Third-party service providers (including contractors and designated agents) and other entities in our group who will participate in performing the services to you as
                        subcontractors or otherwise.<br />
                        • Professional advisers including lawyers, bankers, auditors and insurers who provide consultancy, banking, legal, insurance and accounting services.<br />
                        • Enforcement authorities, regulators and other authorities who require reporting of processing activities in certain circumstances.<br />
                        • Payment processors who provide services on processing payments.<br /><br />
                        Internal Third Parties means other companies in AEER Group acting as joint controllers or processors and provide IT and system administration services and undertake
                        leadership reporting.
                    </p>
                    <h3>1.2. Controller</h3>
                    <p>
                        AEER Platform Inc is the controller and responsible for Website and mobile app. This privacy policy is issued on behalf of us. <br /><br />
                        We have appointed a data protection officer (“DPO”) who is responsible for overseeing questions in relation to this privacy policy. If you have any questions about this
                        privacy policy, including any requests to exercise, please contact the DPO using the details set out below. <br /><br />
                        Controller’s contact details <br /><br />
                        Full name of legal entity: AEER Platform Inc.<br />
                        Contact details:<br />
                        - Email: example@email.com<br />
                        - Address: 8 The Green Ste A Dover, DE 19901, United States of America<br />
                        - Attn: Data Protection Officer<br /><br />
                        Third-party service providers (including contractors and designated agents) and other entities within the business procedure may act as processors to collect, store and
                        process your personal data. Other persons or entities may be treated as third parties on case-by-case basis
                    </p>
                    <h3>1.3. Purpose of this privacy policy</h3>
                    <p>
                        This privacy policy aims to give you information on how AEER collects and processes your personal data through your use of this website, and any other website
                        operated by us from which you are accessing this Policy (each a “Website”) or via mobile app and our Services will only be used in accordance with this privacy policy,
                        including any data you may provide through the Website when you sign up to our newsletter, request for commercial proposal or purchase a product or service. <br /><br />
                        This privacy policy applies to all AEER services that do not have a separate privacy policy or that link to this privacy policy, which we call the “Services.” <br /><br />
                        The Website or mobile app is not intended for children, and we do not knowingly collect data relating to children. <br /><br />
                        It is important that you read this privacy policy together with any other privacy notice or other notice we may provide on specific occasions when we are collecting or
                        processing personal data about you so that you are fully aware of how and why we are using your data. 
                    </p>
                    <h3>1.4. Policy update</h3>
                    <p>We may update this Product Privacy Policy on occasional basis if we change internal procedures or once we are required to do so by law. We may at our sole discretion
                    use additional notification on policy update, for example, pop-up notification, email or Online Cabinet or Profile notice on such change which may have immediate
                    effect. The Customer confirms its awareness and consent to changed Policy by continuing access to the Website, or mobile app, or the user interface of the Software
                    upon change. By using the Service, Customer accepts changed terms and conditions in full. If Customer disagrees with these terms and conditions or any part of these
                    terms and conditions, Customer must not use this service</p>
                    <h2>2. WHICH DATA WE COLLECT ABOUT YOU?</h2>
                    <h3>2.1. Personal data</h3>
                    <p>
                        
                    For individuals covered by the General Data Protection Regulation (“GDPR”), personal data is any information relating to an identified or identifiable natural person.
                    For individuals not covered by GDPR, personal data is any information relating to an identified or identifiable natural person. For example, your name, address,
                    telephone number, e-mail address, government issued identification information, education information, payroll information, as well as any other non-public
                    information about you that is associated with or linked to any of the foregoing data. It does not include data where the identity has been removed (anonymous data
                    or statistics data).
                    </p>
                    <h3>2.2. Categories of Personal data</h3>
                    <p>We may collect, use, store and transfer different kinds of personal data about you (or if you are a business about your employees, contractors, candidates other
                    individuals, provided you have received a written consent of those persons to share their personal data with us) which we have grouped together as follows: <br />
                    • Identity Data includes first name, middle name, last name, username or similar identifier, date of birth and gender.. <br />
                    • Contact Data includes billing address, email address and telephone numbers. <br />
                    • Finance Data includes details about your expenses and incomes which you put into AEER application as part of Finances feature for personal finance and
                    budgeting. <br />
                    • Transaction Data includes details about payments to and from you and other details of products and services you have purchased from us. <br />
                    • Technical Data includes internet protocol (IP) address, your login data, time zone setting and location, operating system and platform and other technology on
                    the devices you use to access this website or mobile app. <br />
                    • Profile Data includes your username and password, purchases or orders made by you, your interests, preferences, feedback and survey responses. <br />
                    • Usage Data includes information about how you use our website, products and services, or mobile app. <br />
                    • Marketing and Communications Data includes your preferences in receiving marketing from us and our third parties and your communication preferences. <br /><br />
                    We also collect, use and share Aggregated Data such as statistical or demographic data for any purpose. Aggregated Data may be derived from your personal data but
                    is not considered personal data in law as this data does not directly or indirectly reveal your identity. For example, we may aggregate your Usage Data to calculate the
                    percentage of users accessing a specific website/ mobile app feature. However, if we combine or connect Aggregated Data with your personal data so that it can
                    directly or indirectly identify you, we treat the combined data as personal data which will be used in accordance with this privacy policy. <br /><br />
                    <b>Special Categories of Data</b><br /><br />
                    We do not intendedly collect any Special Categories of Personal Data about you (this includes details about your race or ethnicity, religious or philosophical beliefs, sex
                    life, sexual orientation, political opinions, trade union membership, information about your health, or genetic and biometric data). We do not collect any information
                    about criminal convictions and offences. <br /><br />
                    <b>If you fail to provide personal data</b><br />
                    Where we need to collect personal data by law, or under the terms of a contract we have with you and you fail to provide that data when requested, we may not be
                    able to perform the contract we have or are trying to enter into with you (for example, to provide you with goods or services). In this case, we may have to cancel a
                    product or service you have with us, but we will notify you if this is the case at the time. <br /><br />
                    Your duty to inform us of changes
                    It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with
                    us. <br /><br />
                    <b>Third-party links</b><br />
                    Website may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or
                    share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our website, we encourage
                    you to read the privacy policy of every website you visit.
                    </p>

                    <h3>3. HOW WE COLLECT YOUR PERSONAL DATA?</h3>
                    <p>
                        We use different methods to collect data from and about you including through: <br /><br />
                        • Direct interactions. You may give us your Identity data, Contact data by filling in forms or by corresponding with us by post, phone, email, or in course of using
                        Service or otherwise. This includes personal data you provide when you:<br />
                        1. apply for our products or Services;<br />
                        2. create an account on our Website or mobile app;<br />
                        3. subscribe to our Service or publications;<br />
                        4. request marketing to be sent to you;<br />
                        5. enter a competition, promotion or survey; or<br />
                        6. give us some feedback. <br /><br />
                        • Automated technologies or interactions. As you interact with our website or mobile app, we may automatically collect Technical Data about your equipment,
                        browsing actions and patterns. We collect this personal data by using cookies, server logs and other similar technologies. We may also receive Technical Data
                        about you if you visit other websites employing our cookies. Please see our information about how we use cookies for further details in our Cookie Policy.<br /><br />
                        • Third parties’ or publicly available sources. We may receive personal data about you from various third parties and public sources as set out below:
                        Technical Data from the following parties:<br />
                        (a) analytics providers such as Google based inside or outside the EU;<br />
                        (b) advertising networks based inside or outside the EU; and<br />
                        (c) search information providers based inside or outside the EU.
                    </p>	

                    <h3>4. HOW WE USE YOUR PERSONAL DATA?</h3>
                    <p><b>4.1. Legal basis</b> <br />
                    We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances: <br />
                    • Where we need to perform the contract, we are about to enter into or have entered into with you; <br />
                    • Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests; <br />
                    • Where we need to comply with a legal or regulatory obligation. <br /><br />
                    Generally, we do not rely on consent as a legal basis for processing your personal data other than in relation to sending third party direct marketing communications
                    to you via email or text message. You have the right to withdraw consent to marketing at any time by contacting us. <br /><br />
                    <b>4.2. Purposes for which we will use your personal data</b><br />
                    We have set out below, in a table format, a description of all the ways we plan to use your personal data and determine the legal basis we rely on to do so. We have
                    also identified our legitimate interests, where appropriate. Note that we may process your personal data for more than one lawful ground depending on the specific
                    purpose for which we are using your data. Please contact us if you need details about the specific legal ground, we are relying on to process your personal data where
                    more than one ground has been set out in the table below</p><br />
                    
                    <table>
                        <tbody>
                            <tr>
                                <th>PURPOSE/ACTIVITY</th>
                                <th>TYPE OF PERSONAL DATA</th>
                                <th>LEGAL BASIS FOR PROCESSING </th>
                            </tr>
                            <tr>
                                <td>To register you as a new customer</td>
                                <td>
                                    (a) Identity data <br />
                                    (b) Contact data
                                </td>
                                <td>Entering into and performance of a contract with you</td>
                            </tr>
                            <tr>
                                <td>
                                    To process and deliver your order including: <br />
                                    (a) Prepare a commercial proposal or draft of <br />
                                    the contract for the supply of services <br />
                                    (b) Manage payments, fees and charges <br />
                                    (c) Collect and recover money owed to us
                                </td>
                                <td>
                                    (a) Identity data <br />
                                    (b) Contact data <br />
                                    (c) Transaction data <br />
                                    (d) Marketing and Communications data 
                                </td>
                                <td>
                                    (a) Performance of a contract with you <br />
                                    (b) Necessary for our legitimate interests (to recover debts due to us)
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    To manage our relationship with you which will include: <br />
                                    (a) Notifying you about changes to our terms or
                                    privacy policy <br />
                                    (b) Asking you to leave a review or take a survey
                                </td>
                                <td>
                                    (a) Identity data <br />
                                    (b) Contact data <br />
                                    (c) Profile data <br />
                                    (d) Marketing and Communications data
                                </td>
                                <td>
                                    (a) Performance of a contract with you <br />
                                    (b) Necessary to comply with a legal obligation <br />
                                    (c) Necessary for our legitimate interests (to keep our records updated
                                    and to study how customers use our products/services)
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    To enable you to partake in a prize draw, competition or complete a survey
                                </td>
                                <td>
                                    (a) Identity data <br />
                                    (b) Contact data <br />
                                    (c) Profile data <br />
                                    (d) Usage data <br />
                                    (e) Marketing and Communications data
                                </td>
                                <td>
                                    (a) Performance of a contract with you <br />
                                    (b) Necessary for our legitimate interests (to study how customers use our products/services, to develop them and grow our business)
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    To administer and protect our business and this
                                    website or mobile app (including
                                    troubleshooting, data analysis, testing, system
                                    maintenance, support, reporting and hosting of
                                    data)
                                </td>
                                <td>
                                    (a) Identity data <br />
                                    (b) Contact data <br />
                                    (c) Technical data
                                </td>
                                <td>
                                    (a) Necessary for our legitimate interests (for running our business, provision of administration and IT services, network security, to prevent fraud and in the context of a business reorganization or group restructuring exercise) <br /> 
                                    (b) Necessary to comply with a legal obligation
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    To deliver relevant website or mobile app content and advertisements to you and measure or understand the effectiveness of the advertising we serve to you
                                </td>
                                <td>
                                    (a) Identity data <br />
                                    (b) Contact data <br />
                                    (c) Profile data <br />
                                    (d) Usage data <br />
                                    (e) Marketing and Communications data <br />
                                    (f) Technical data
                                </td>
                                <td>
                                    Necessary for our legitimate interests (to study how customers use our products/services, to develop them, to grow our business and to inform our marketing strategy)
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    To use data analytics to improve our website or mobile app, products/services, marketing, customer relationships and experiences
                                </td>
                                <td>
                                    (a) Identity data <br />
                                    (b) Technical data <br />
                                    (с) Usage data
                                </td>
                                <td>
                                    Necessary for our legitimate interests (to define types of customers for our products and services, to keep our website updated and relevant, to develop our business and to inform our marketing strategy)
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    To provide a service of providing statistics and calculating your expenses and incomes within the Finance Feature
                                </td>
                                <td>
                                    (a) Identity data <br />
                                    (b) Technical data <br />
                                    (c)Finance data <br />
                                    (d)Usage data
                                </td>
                                <td>
                                    (a) Performance of a contract with you <br />
                                    (b) Necessary for our legitimate interests (to study how customers use our products/services, to develop them and grow our business)
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    To make suggestions and recommendations to you about goods or services that may be of interest to you
                                </td>
                                <td>
                                    (a) Identity data <br />
                                    (b) Contact data <br />
                                    (c) Technical data <br />
                                    (d) Usage data <br />
                                    (e) Profile data
                                </td>
                                <td>
                                    Necessary for our legitimate interests (to develop our products/services and grow our business)
                                </td>
                            </tr>
                        </tbody>
                    </table>				

                    <p><b>4.3. Marketing</b><br />
                    We strive to provide you with choices regarding certain personal data uses, particularly around marketing and advertising. Our email messages have unsubscribe
                    feature. <br /><br />
                    - Promotional offers from us. We may use your Identity, Contact, Technical, Usage and Profile Data to form a view on what we think you may want or need, or what may
                    be of interest to you. This is how we decide which products, services and offers may be relevant for you (we call this marketing). You will receive marketing
                    communications from us if you have requested information from us or purchased goods or services from us or if you provided us with your details when you entered
                    a competition or registered for a promotion and, in each case, you have not opted out of receiving that marketing.<br />
                    - Third-party marketing. We will get your express opt-in consent before we share your personal data with any company outside AEER for marketing purposes. <br /><br />
                    <b>4.4. Opting out</b><br />
                    You can ask us or third parties to stop sending you marketing messages at any time by contacting us at any time. Where you opt out of receiving these marketing
                    messages, this will not apply to personal data provided to us as a result of a product/service purchase, warranty registration, product/service experience or other
                    transactions. <br /><br />
                    <b>4.5. Cookies</b><br />
                    You can set your browser to refuse all or some browser cookies, or to alert you when website or mobile app set or access cookies. If you disable or refuse cookies,
                    please note that some parts of this website may become inaccessible or not function properly. For more information about the cookies we use, please see our Cookies
                    Policy. <br /><br />
                    <b>4.6. Change of purpose</b><br />
                    We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason
                    is compatible with the original purpose. If you wish to get an explanation as to how the processing for the new purpose is compatible with the original purpose,
                    please contact us. <br /><br />
                    If we need to use your personal data for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so or ask for your consent to
                    further processing for the new purpose. <br /><br />
                    Please note that we may process your personal data without your knowledge or consent, in compliance with the above rules, where this is required or permitted by
                    law.</p>

                    <h3>5. DISCLOSURES OF YOUR PERSONAL DATA</h3>

                    <p><b>5.1. With Subcontractors (Service Providers)</b><br />
                    We reserve our right to engage (subcontract) other third-party service providers to provide services on our behalf to visitors to our websites, our customers of the
                    Service and may need to share your information with them to provide information, products or services to you. Examples may include analyzing data or performing
                    statistical analysis or calculations, providing marketing assistance, processing credit card payments, supplementing the information you provide us in order to provide
                    you with better service, and providing customer service or support. These service providers are prohibited from using your Personal Data except for these purposes,
                    and they are required to maintain the confidentiality of your information. In all cases where we transfer your personal data to such recipients, we explicitly require the
                    receiving party to acknowledge and adhere to our privacy rules.<br /><br />
                    <b>5.2. Corporate-related cases</b><br />
                    If we (or our assets) are acquired by another company, whether by merger, acquisition, bankruptcy or otherwise, that company would receive all information gathered
                    by AEER on the websites and the Subscription Service. In this event, you will be notified via email and/or a prominent notice on our website, of any change in ownership,
                    uses of your Personal Data, and choices you may have regarding your Personal Data (e.g., request for data deletion, which may in some cases mean that you cannot
                    use the Service any longer). The same might be applicable to mobile app.<br /><br />
                    <b>5.3. Mandatory Disclosure</b><br />
                    We reserve the right to use or disclose your Personal Data if required by law, by court order. Also, we reserve our right to use or disclose your Personal Data if such
                    use or disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or comply with a law.
                    We require all third parties to respect the security of your personal data and to treat it in accordance with the law. We do not allow our third-party service providers
                    to use your personal data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions.<br /><br />
                    <b>5.4. Product-specific cases</b> <br />
                    We reserve our right to make any product-specific rules for data processing and update you as the case may be.</p>

                    <h3>6. INTERNATIONAL TRANSFERS</h3>
                    <p><b>6.1. International Transfers within AEER’s Entities</b><br />
                    To facilitate our global operations, we may transfer information to other countries and allow access to that information from countries in which the AEER Affiliates
                    have operations for the purposes described in this policy. This Privacy Policy shall apply even if we transfer Personal Data to other countries. We have taken appropriate
                    safeguards to require that your Personal Data will remain protected. When we share information about you within and among AEER’s Affiliates, we make use of
                    standard contractual data protection clauses, which have been approved by the European Commission. <br /><br />
                    <b>6.2. International Transfers to Third Parties</b><br />
                    Certain third parties, which provide services to us under contract, are based in other countries that may not have equivalent privacy and data protection laws
                    (inadequate level of data protection). When we share information of customers in the European Economic Area or Switzerland, we make use a variety of legal
                    mechanisms to safeguard the transfer, including the European Commission-approved standard contractual data protection clauses or other appropriate legal
                    mechanisms. For transfers to or from the United States, we are compliant with Article 49 of GDPR; other transfer may be performed on standard contractual clauses
                    or other valid instruments. Please contact us if you need more information about the legal mechanisms, we rely on to transfer Personal Information outside the EEA,
                    Switzerland and the United States.</p>

                    <h3>7. DATA SECURITY</h3>
                    <p>We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorized way, altered or
                    disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They
                    will only process your personal data on our instructions and they are subject to a duty of confidentiality.
                    We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally
                    required to do so</p>	

                    <h3>8. HOW LONG WE WILL USE YOUR DATA?</h3>
                    <p>
                        We will only retain your personal data for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting,
                        or reporting requirements. <br /><br />
                        To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from
                        unauthorized use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other
                        means, and the applicable legal requirements.<br /><br />
                        - Customer Data collected during your use of the Service is retained for as long as you have a paid Subscription and/or remain an active customer in your portal.
                        Your data is deleted upon your written request or after an established period following the termination of all customer agreements. In general, Customer Data
                        is deleted after your paid Subscription ends plus one year, when your account becomes inactive. Some personal data is stored longer if we have legal basis for
                        doing so or if we are required to extended data storing under the law (for example, if data is necessary for taxation and accounting needs).<br />
                        - By law we have to keep basic information about our customers (including Contact, Identity, Financial and Transaction Data) for six years after they cease being
                        customers for tax purposes.<br />
                        In some circumstances you can ask us to delete your data: see Request erasure below for further information. <br /><br />
                        In some circumstances we may anonymize your personal data (so that it can no longer be associated with you) for research or statistical purposes in which case we
                        may use this information indefinitely without further notice to you.</p>

                    <h3>9. YOUR LEGAL RIGHTS</h3>

                    
                    <p>
                        <b>9.1. Brief list of your rights</b><br />
                        Under certain circumstances, you have rights under data protection laws in relation to your personal data. Please click on the links below to find out more about these
                        rights: <br /><br />
                        - Request access to your personal data;<br />
                        - Request correction of your personal data;<br />
                        - Request erasure of your personal data (right to be forgotten);<br />
                        - Object to processing of your personal data;<br />
                        - Request restriction of processing your personal data;<br />
                        - Request to data portability;<br />
                        - Right to withdraw consent;<br />
                        - Right to lodge complaint with supervisory authority.<br /><br />
                        <b>9.2. You have the right to:</b><br />
                        Request access to your personal data (commonly known as a "data subject access request"). This enables you to receive a copy of the personal data we hold about you
                        and to check that we are lawfully processing it. <br /><br />

                        Request correction of the personal data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we
                        may need to verify the accuracy of the new data you provide to us. <br /><br />

                        Request erasure of your personal data. This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You
                        also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we
                        may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note, however, that we may not
                        always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request. <br /><br />

                        Object to processing of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation
                        which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where
                        we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your
                        information which override your rights and freedoms. <br /><br />

                        Request restriction of processing of your personal data. This enables you to ask us to suspend the processing of your personal data in the following scenarios: (a) if you
                        want us to establish the data`s accuracy; (b) where our use of the data is unlawful but you do not want us to erase it; (c) where you need us to hold the data even if we
                        no longer require it as you need it to establish, exercise or defend legal claims; or (d) you have objected to our use of your data but we need to verify whether we have
                        overriding legitimate grounds to use it. <br /><br />

                        Data portability of your personal data to you or to a third party. We will provide to you, or a third party you have chosen, your personal data in a structured, commonly
                        used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the
                        information to perform a contract with you. <br /><br />
                        Withdraw consent at any time where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any processing carried
                        out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is
                        the case at the time you withdraw your consent. <br /><br />
                        Lodge complaint with supervisory authority
                        You have the right to lodge a complaint with the ICO at https://ico.org.uk/concerns/.
                        If you wish to exercise any of the rights set out above, please contact us.<br /><br />
                        <b>9.3. No fee usually required</b><br />
                        You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly
                        unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your request in these circumstances.<br /><br />
                        <b>9.4. What we may need from you</b><br />
                        We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your
                        other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you
                        for further information in relation to your request to speed up our response.<br /><br />
                        <b>9.5. Time limit to respond</b><br />
                        We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex or you have
                        made a number of requests. In this case, we will notify you and keep you updated.<br /><br />
                        <b>9.6. Complaints</b><br />
                        If you have any questions, concerns or complaints regarding our compliance with this notice or data protection law, or if you wish to exercise your rights, we encourage
                        you to first contact us at marketing@expressglobalemployment.com. We will investigate and attempt to resolve complaints and disputes and will make every
                        reasonable effort to honor your wish to exercise your rights as quickly as possible and in any event, within the timescales provided by data protection laws. <br /><br />
                        If you are dissatisfied with AEER ’s handling of any privacy complaints, if you do not receive timely acknowledgement of your complaint, or your complaint is not
                        satisfactorily addressed please contact supervisory authority. Contact information for these authorities can be accessed at
                        http://ec.europa.eu/newsroom/article29/item-detail.cfm?item_id=612080.
                    </p>

                    <h3>10. INFORMATION ABOUT OUR USE OF COOKIES</h3>
                    <p>Our website uses cookies to distinguish you from other users of our website. A cookie is a small file of letters and numbers that we store on your browser or the hard
                    drive of your computer if you agree. Cookies contain information that is transferred to your computer`s hard drive. This helps us to provide you with a good experience
                    when you browse our website and allows us to improve our site. By continuing to browse the site, you are agreeing to our use of cookies. The same may be applicable
                    to mobile app.
                    More details about cookies which we use find in our Cookie Policy. </p>

                    <h3>11. Purchases and Subscriptions</h3> <br />
                    <p>
                        1. If you wish to purchase any product or service made available through Service (“Purchase”),  you may be asked to supply certain information relevant to your Purchase including, without  limitation, your credit card number, the expiration date of your credit card, your billing address,  and your shipping information. <br />
                        You represent and warrant that: (i) you have the legal right to use any credit card(s) or other  payment method(s) in connection with any Purchase; and that (ii) the information you supply  to us is true, correct and complete.<br />
                        We may employ the use of third party services for the purpose of facilitating payment and the  completion of Purchases. By submitting your information, you grant us the right to provide the  information to these third parties subject to our Privacy Policy.<br />
                        We reserve the right to refuse or cancel your order at any time for reasons including but not  limited to: product or service availability, errors in the description or price of the product or  service, error in your order or other reasons.<br />
                        We reserve the right to refuse or cancel your order if fraud or an unauthorized or illegal  transaction is suspected.   <br /><br />
                        2. <b>Contests, Sweepstakes and Promotions</b> <br />
                        Any contests, sweepstakes or other promotions (collectively, <b>“Promotions”</b>) made available  through Service may be governed by rules that are separate from these Terms of Service. If  you participate in any Promotions, please review the applicable rules as well as our Privacy  Policy. If the rules for a Promotion conflict with these Terms of Service, Promotion rules will  apply. <br /><br />
                        3. <b>Subscriptions </b><br />
                        Some parts of Service are billed on a subscription basis (<b>“Subscription(s)”</b>). You will be billed  in advance on a recurring and periodic basis (<b>“Billing Cycle”</b>). Billing cycles are set either on  a monthly or annual basis, depending on the type of subscription plan you select when  purchasing a Subscription.
                        At the end of each Billing Cycle, your Subscription will automatically renew under the exact  same conditions unless you cancel it or AEER Platform Inc. cancels it. You may cancel your  Subscription renewal either through your online account management page or by  contacting AEER Platform Inc, Inc. customer support team.
                        A valid payment method, including credit card or PayPal, is required to process the payment  for your subscription. You shall provide AEER Platform Inc, Inc. with accurate and complete billing  information including full name, address, state, zip code, telephone number, and a valid  payment method information. By submitting such payment information, you automatically  authorize AEER Platform Inc, Inc. to charge all Subscription fees incurred through your account to any such  payment instruments.
                        Should automatic billing fail to occur for any reason, AEER Platform Inc will issue an electronic invoice  indicating that you must proceed manually, within a certain deadline date, with the full payment  corresponding to the billing period as indicated on the invoice.<br /><br />
                        4. <b>Free Trial</b>  <br />
                        AEER Platform Inc may, at its sole discretion, offer a Subscription with a free trial for a limited period  of time (<b>“Free Trial”</b>).
                        You may be required to enter your billing information in order to sign up for Free Trial.
                        If you do enter your billing information when signing up for Free Trial, you will not be charged  by AEER Platform Inc. until Free Trial has expired. On the last day of Free Trial period, unless you  cancelled your Subscription, you will be automatically charged the applicable Subscription  fees for the type of Subscription you have selected.
                        At any time and without notice, AEER Platform, Inc. reserves the right to (i) modify Terms of Service of  Free Trial offer, or (ii) cancel such Free Trial offer. <br /><br />
                        5. <b>Fee Changes</b>  <br />
                        AEER Platform, Inc., in its sole discretion and at any time, may modify Subscription fees for the  Subscriptions. Any Subscription fee change will become effective at the end of the then current Billing Cycle. <br />
                        AEER Platform, Inc. will provide you with a reasonable prior notice of any change in Subscription fees  to give you an opportunity to terminate your Subscription before such change becomes  effective. Your continued use of Service after Subscription fee change comes into effect constitutes  your agreement to pay the modified Subscription fee amount. <br /><br />
                        6. <b>Refunds</b> <br />
                        Except when required by law, paid Subscription fees are non-refundable.
                    </p>
                </div>	
            </div>
            <MainFooter />
        </>
    )
}

export default Privacy;

export const Head: HeadFC = () => (
    <>
        <title>Privacy - AEER PLATFORM</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
    </>
);
